
import './App.css';
import React, { Component } from 'react'; //different
import Postman from './postmain'
import "@fontsource/kanit"; // Defaults to weight 400
import "@fontsource/kanit/400.css"; // Specify weight
import "@fontsource/kanit/400-italic.css"; // Specify weight and style
import CheckboxGroup from 'react-checkbox-group'
import axios from 'axios';
import {
    Switch,
} from '@material-ui/core';
import Swal from 'sweetalert2'
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cuslist: null,
            cuslist1: null,
            cuslist2: null,
            cuslist3: null,
            pricelist: 0,
            pricemonth: 0,
        }
    }
    toggleModal = async (listitem, no) => {
        var cuslist = this.state.cuslist;
        var cuslist1 = this.state.cuslist1;
        var cuslist2 = this.state.cuslist2;
        var cuslist3 = this.state.cuslist3;
        let desc = "";
        let title = "";
        if (no == 1) {
            for (let i = 0; i < cuslist.length; i++) {
                if (cuslist[i].systemid == listitem.systemid) {
                    desc = cuslist[i].description
                    title = cuslist[i].title
                    break;
                }
            }
        }
        else if (no == 2) {
            for (let i = 0; i < cuslist1.length; i++) {
                if (cuslist1[i].systemid == listitem.systemid) {
                    desc = cuslist1[i].description
                    title = cuslist1[i].title
                    break;
                }
            }
        }
        else if (no == 3) {
            for (let i = 0; i < cuslist2.length; i++) {
                if (cuslist2[i].systemid == listitem.systemid) {
                    desc = cuslist2[i].description
                    title = cuslist2[i].title
                    break;
                }
            }
        }
        else if (no == 4) {
            for (let i = 0; i < cuslist3.length; i++) {
                if (cuslist3[i].systemid == listitem.systemid) {
                    desc = cuslist3[i].description
                    title = cuslist3[i].title
                    break;
                }
            }
        }
        await Swal.fire({
            title: "<strong>" + title + "</strong>",
            icon: "info",
            html: desc,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: `
              <i class="fa fa-thumbs-up"></i> Great!
            `,
            confirmButtonAriaLabel: "Thumbs up, great!",
        });

    };
    toggleCheck2(listitem, no) {
        let pricelist = 0;
        let pricemonth = 0;
        var cuslist = this.state.cuslist;
        var cuslist1 = this.state.cuslist1;
        var cuslist2 = this.state.cuslist2;
        var cuslist3 = this.state.cuslist3;
        if (no == 1) {
            for (let i = 0; i < cuslist.length; i++) {
                if (cuslist[i].systemid == listitem.systemid) {
                    if (cuslist[i].statusx == 1) cuslist[i].statusx = 0
                    else cuslist[i].statusx = 1
                }
                else {
                    cuslist[i].statusx = 0
                }
            }
        }
        else if (no == 2) {
            for (let i = 0; i < cuslist1.length; i++) {
                if (cuslist1[i].systemid == listitem.systemid) {
                    if (cuslist1[i].statusx == 1) cuslist1[i].statusx = 0
                    else cuslist1[i].statusx = 1
                }
                else {
                    cuslist1[i].statusx = 0
                }
            }
        }
        else if (no == 3) {

            Swal.fire({
                title: 'Warning!',
                text: 'ฟังกชั่นจำเป็นไม่สามารถปิดใช้งานได้',
                icon: 'warning',
                confirmButtonText: 'ตกลง'
            })
            //     for (let i = 0; i < cuslist2.length; i++) {
            //         if (cuslist2[i].systemid == listitem.systemid) {
            //             if (cuslist2[i].statusx == 1) cuslist2[i].statusx = 0
            //             else cuslist2[i].statusx = 1
            //             break;
            //         }
            //     }
        }
        else if (no == 4) {
            for (let i = 0; i < cuslist3.length; i++) {
                if (cuslist3[i].systemid == listitem.systemid) {
                    if (cuslist3[i].statusx == 1) cuslist3[i].statusx = 0
                    else cuslist3[i].statusx = 1
                    break;
                }
            }
        }
        for (let i = 0; i < cuslist.length; i++) {
            if (cuslist[i].statusx == 1) {
                pricelist = pricelist + cuslist[i].cal_cash
                pricemonth = pricemonth + cuslist[i].cal_xo
            }
        }
        for (let i = 0; i < cuslist1.length; i++) {

            if (cuslist1[i].statusx == 1) {
                pricelist = pricelist + cuslist1[i].cal_cash
                pricemonth = pricemonth + cuslist1[i].cal_xo

            }
        }
        for (let i = 0; i < cuslist2.length; i++) {

            if (cuslist2[i].statusx == 1) {
                pricelist = pricelist + cuslist2[i].cal_cash
                pricemonth = pricemonth + cuslist2[i].cal_xo
            }
        }
        for (let i = 0; i < cuslist3.length; i++) {

            if (cuslist3[i].statusx == 1) {
                pricelist = pricelist + cuslist3[i].cal_cash
                pricemonth = pricemonth + cuslist3[i].cal_xo
            }
        }



        this.setState({
            cuslist: cuslist,
            cuslist1: cuslist1,
            cuslist2: cuslist2,
            cuslist3: cuslist3,
            pricelist: pricelist,
            pricemonth: pricemonth,
        })
    }
    async componentDidMount() {
        await axios.get('https://apixgen.javisauto.live:8800/api/v1/getsystem').then((res) => {
            if (res.data.status === 200) {
                let pricemonth = 0;
                let pricelist = 0;
                for (let i = 0; i < res.data.tblinfomation1.length; i++) {
                    if (res.data.tblinfomation1[i].statusx == 1) {
                        pricelist = pricelist + res.data.tblinfomation1[i].cal_cash
                        pricemonth = pricemonth + res.data.tblinfomation1[i].cal_xo
                    }
                }
                for (let i = 0; i < res.data.tblinfomation2.length; i++) {

                    if (res.data.tblinfomation2[i].statusx == 1) {
                        pricelist = pricelist + res.data.tblinfomation2[i].cal_cash
                        pricemonth = pricemonth + res.data.tblinfomation2[i].cal_xo

                    }
                }
                for (let i = 0; i < res.data.tblinfomation3.length; i++) {

                    if (res.data.tblinfomation3[i].statusx == 1) {
                        pricelist = pricelist + res.data.tblinfomation3[i].cal_cash
                        pricemonth = pricemonth + res.data.tblinfomation3[i].cal_xo
                    }
                }
                for (let i = 0; i < res.data.tblinfomation4.length; i++) {

                    if (res.data.tblinfomation4[i].statusx == 1) {
                        pricelist = pricelist + res.data.tblinfomation4[i].cal_cash
                        pricemonth = pricemonth + res.data.tblinfomation4[i].cal_xo
                    }
                }
                this.setState({
                    cuslist: res.data.tblinfomation1,
                    cuslist1: res.data.tblinfomation2,
                    cuslist2: res.data.tblinfomation3,
                    cuslist3: res.data.tblinfomation4,
                    pricelist: pricelist,
                    pricemonth: pricemonth,
                })

            }

        }).catch((error) => {
            console.log(error)
        });
    }


    render() {
        return (
            <div>
                <Postman />
                <main id="main">
                    {/* ======= About Us Section ======= */}
                    <section id="about" className="about">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>เกี่ยวกับเรา</h2>
                                <div id="text_block-23-117" className="ct-text-block">Jarvis Auto</div>
                                <p >  <span style={{ marginLeft: "50px" }}> เปิดเว็บพนันออนไลน์ ถือสู้สูงสุด 93% ออกแบบเว็บ สร้างเว็บคาสิโน </span>
                                    เปิดเว็บเอเย่นต์ เปิดเว็บพนันออนไลน์ ได้อย่างสมบูรณ์ส่งตรงจาก Javis เราเปิดผู้ให้บริการ เปิดเว็บพนันออนไลน์แท้ และเจ้าเดียวที่รับประกันความปลอดภัย ออกแบบเว็บพนันออนไลน์ บริการครบครัน จบในที่เดียว! อยากได้อะไร Javis พร้อมจัดให้คุณเต็มที่รวบรวมประเภทเกม เปิดเว็บสล็อต คาสิโนออนไลน์ หวย บอลและอีกมากมาย แบบครบวงจร</p>
                            </div>
                            <section id="" className="">
                                <div className="container text-center" data-aos="zoom-out">
                                    <img src="assets/img/Capture.JPG" alt="" className="img-fluid" />
                                </div>
                            </section>

                            <div className="row gy-4">
                                <div className="col-lg-6">
                                    <h3>สามารถเลือกฟังก์ชั่นการใช้งานได้</h3>
                                    <img src="https://www.techiexpert.com/wp-content/uploads/2023/12/image-13.png" className="img-fluid rounded-4 mb-4" alt="" />
                                    <p>ผู้ต้องการเปิดเว็บสามารถ เลือกฟังก์ชั่นที่ต้องการและไม่ต้องการได้ เพื่อปรับลดรายเดือน หรือปรับเปลี่ยนฟังก์ชั่นเพิ่มเติมได้ตลอด</p>
                                    <p>ซึ่งเหมาะสมกับผู้ทำการเปิดเริ่มต้น เพื่อลดค่าไช้จ่ายรายเดือนได้ มีทั้งเปิดแบบมีหน้ากระดานและไม่มีหน้ากระดาน ซื้อเครดิตขาด หรือ เครดิตหมุนเวียน</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="content ps-0 ps-lg-5">
                                        <p className="fst-italic">
                                            ระบบออโต้รองรับหลายธนาคาร ซัพพอตการตลาด สามารถทำ SEO หน้าเว็บไซต์ได้จากหลังบ้าน
                                        </p>
                                        <ul className='checklist'>
                                            <li><i className="bi bi-check-circle-fill" />รองรับ หลากลายธนาคาร ไทยพานิช ,กรุงศรี ,กสิกร</li>
                                            <li><i className="bi bi-check-circle-fill" />รองรับระบบ Payment Gateway 1.9%</li>
                                            <li><i className="bi bi-check-circle-fill" />รองรับหลายกระดาน Betflix,Xgambet,Ambking,Uefa</li>
                                            <li><i className="bi bi-check-circle-fill" />ระบบ Afficiate หลายระดับ ระบบสายงาน</li>
                                            <li><i className="bi bi-check-circle-fill" />ระบบ ปรับแตก</li>
                                            <li><i className="bi bi-check-circle-fill" />ระบบ ชวนเพื่อน</li>
                                            <li><i className="bi bi-check-circle-fill" />ระบบ ติดตามลิงค์ว่ามีจำนวนคนคลิกกี่คน</li>
                                            <li><i className="bi bi-check-circle-fill" />ระบบ ตั้งค่าแจ้งเตือนไลน์ต่างๆ</li>
                                            <li><i className="bi bi-check-circle-fill" />ระบบ ไลน์ Officail การตลาด Rich menu</li>
                                            <li><i className="bi bi-check-circle-fill" />อื่นๆ</li>
                                        </ul>
                                        <p>
                                            ฟังก์ชั่นเสริมมากมาย ไม่มีข้อจำกัด หากต้องการเพิ่มฟังก์ชั่น เพื่อไห้ง่ายต่อการใช้งาน มีทีมซัพพอต 24ชม.
                                        </p>
                                        <div className="position-relative mt-4">
                                            <img src="https://www.casinopapa.co.uk/wp-content/uploads/2018/10/casino-customer-support.png" className="img-fluid rounded-4" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>{/* End About Us Section */}
                    {/* ======= Clients Section ======= */}
                    <section id="clients" className="clients">
                        <div className="container" data-aos="zoom-out">
                            <div className="clients-slider swiper">
                                <div className="swiper-wrapper align-items-center">
                                    <div className="swiper-slide"><img src="https://bo.javisauto.live/reward/Capture.JPG" className="img-fluid" alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </section>{/* End Clients Section */}
                    {/* ======= Stats Counter Section ======= */}
                    <section id="stats-counter" className="stats-counter">
                        <div className="container" data-aos="fade-up">
                            <div className="row gy-4 align-items-center">
                                <div className="col-lg-6">
                                    <img src="assets/img/Capturse.JPG" alt="" className="img-fluid" />
                                </div>
                                <div className="col-lg-6">
                                    <div className="stats-item d-flex align-items-center">
                                        <span data-purecounter-start={0} data-purecounter-end={232} data-purecounter-duration={1} className="purecounter" />
                                        <p><strong>ระบบเติมเงินเสถียร</strong> มีหลายระบบรองรับระบบเติมเงิน เปิดปิดระบบได้อย่างรวดเร็ว</p>
                                    </div>{/* End Stats Item */}
                                    <div className="stats-item d-flex align-items-center">
                                        <span data-purecounter-start={0} data-purecounter-end={521} data-purecounter-duration={1} className="purecounter" />
                                        <p><strong>ผู้พัฒนามีประสบการณ์</strong> ประสบการณ์มากกว่า 5 ปีในเว็บพนันทั้งในและต่างประเทศ</p>
                                    </div>{/* End Stats Item */}
                                    <div className="stats-item d-flex align-items-center">
                                        <span data-purecounter-start={0} data-purecounter-end={453} data-purecounter-duration={1} className="purecounter" />
                                        <p><strong>มาตรฐานซัพพอท</strong> ซัพพอท 24 ชม. แก้ปัญหารวดเร็ว</p>
                                    </div>{/* End Stats Item */}
                                </div>
                            </div>
                        </div>
                    </section>{/* End Stats Counter Section */}
                    {/* ======= Call To Action Section ======= */}
                    <section id="call-to-action" className="call-to-action">
                        <div className="container text-center" data-aos="zoom-out">
                            <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox play-btn" />
                            <h3>เชคลิสราคา</h3>
                            <p> สามารถตรวจสอบราคาได้ กดได้ที่นี่</p>
                            <a className="cta-btn" href="#">เชคลิสราคา</a>
                        </div>
                    </section>{/* End Call To Action Section */}
                    {/* ======= Our Services Section ======= */}
                    <section id="services" className="services sections-bg">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>บริการของเรา</h2>
                                <p>บริการของเรามีหลากหลายประเภท ซัพพอตจนคุณเปิดเว็บได้ แนะนำ การทำ SEO แนะนำการทำตลาด เบื้องต้น</p>
                            </div>
                            <div className="row gy-4" data-aos="fade-up" data-aos-delay={100}>
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-item  position-relative">
                                        <div className="icon">
                                            <i className="bi bi-activity" />
                                        </div>
                                        <h3>Payment Gateway</h3>
                                        <p>ค่าธรรมเนียม 1.9% การันตียอดวงเงินไม่มีสูญหายระบบเสถียร รองรับ Qr code ซึ่งเหมาะกับไม่ต้องใช้บัญชีสำหรับลูกค้า.</p>
                                        <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right" /></a>
                                    </div>
                                </div>{/* End Service Item */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-item position-relative">
                                        <div className="icon">
                                            <i className="bi bi-broadcast" />
                                        </div>
                                        <h3>ปรับแต่ง SEO </h3>
                                        <p>รับทำ SEO สร้าง blacklink เพิ่มคะแนน ให้อยู่อันดับต้นๆใน Google search .</p>
                                        <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right" /></a>
                                    </div>
                                </div>{/* End Service Item */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-item position-relative">
                                        <div className="icon">
                                            <i className="bi bi-easel" />
                                        </div>
                                        <h3>การตลาด</h3>
                                        <p>รับทำโฆษณา ทำการตลาด เหมาะสำหรับผู้เริ่มต้นเปิดใหม่ๆ ที่ต้องการลูกค้าเข้ามาติดเว็บไซต์.</p>
                                        <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right" /></a>
                                    </div>
                                </div>{/* End Service Item */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-item position-relative">


                                        <div className="icon">
                                            <i className="bi bi-chat-square-text" />
                                        </div>
                                        <h3>SMS </h3>
                                        <p>ระบบส่ง SMS ข้อความละ 0.55 บาท สามารถส่งพร้อมๆกันได้ ไม่โดนบล๊อคอัตราสำเร็จ 95% .</p>
                                        <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right" /></a>
                                    </div>
                                </div>{/* End Service Item */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-item position-relative">
                                        <div className="icon">
                                            <i className="bi bi-calendar4-week" />
                                        </div>
                                        <h3>ปรับแตก</h3>
                                        <p>ระบบปรับกินเพื่อ ลดค่า  Retrun to player (RTP) ให้เหมาะสม เพื่อไม่ไห้ขาดทุน สามารถตั้งเวลาปรับเปลี่ยนจากระบบจริง.</p>
                                        <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right" /></a>
                                    </div>
                                </div>{/* End Service Item */}
                                <div className="col-lg-4 col-md-6">
                                    <div className="service-item position-relative">
                                        <div className="icon">
                                            <i className="bi bi-bounding-box-circles" />
                                        </div>
                                        <h3>หน้ากระดาน</h3>
                                        <p>มีทั้งเปิดแบบมีหน้ากระดานและไม่มีหน้ากระดาน รองรับหลายหน้ากระดาน Betflix,Xgambet,Ambking,Uefa ซึ่งสามารถเปลี่ยนกระดานได้ตลอดเวลา เครดิตมีแบบซื้ขาดและหมุนเวียน.</p>
                                        <a href="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right" /></a>
                                    </div>
                                </div>{/* End Service Item */}
                            </div>
                        </div>
                    </section>{/* End Our Services Section */}
                    {/* ======= Testimonials Section ======= */}
                    {/* <section id="testimonials" className="testimonials">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>ผู้ใช้บริการ</h2>
                                <p>ผู้ใช้บริการไห้การยอมรับและ</p>
                            </div>
                            <div className="slides-3 swiper" data-aos="fade-up" data-aos-delay={100}>
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                <div className="d-flex align-items-center">
                                                    <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                    <div>
                                                        <h3>Saul Goodman</h3>
                                                        <h4>Ceo &amp; Founder</h4>
                                                        <div className="stars">
                                                            <i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left" />
                                                    Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                                                    <i className="bi bi-quote quote-icon-right" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                <div className="d-flex align-items-center">
                                                    <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                    <div>
                                                        <h3>Sara Wilsson</h3>
                                                        <h4>Designer</h4>
                                                        <div className="stars">
                                                            <i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left" />
                                                    Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                                                    <i className="bi bi-quote quote-icon-right" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                <div className="d-flex align-items-center">
                                                    <img src="assets/img/testimonials/testimonials-3.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                    <div>
                                                        <h3>Jena Karlis</h3>
                                                        <h4>Store Owner</h4>
                                                        <div className="stars">
                                                            <i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left" />
                                                    Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                                                    <i className="bi bi-quote quote-icon-right" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                <div className="d-flex align-items-center">
                                                    <img src="assets/img/testimonials/testimonials-4.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                    <div>
                                                        <h3>Matt Brandon</h3>
                                                        <h4>Freelancer</h4>
                                                        <div className="stars">
                                                            <i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left" />
                                                    Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore.
                                                    <i className="bi bi-quote quote-icon-right" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                <div className="d-flex align-items-center">
                                                    <img src="assets/img/testimonials/testimonials-5.jpg" className="testimonial-img flex-shrink-0" alt="" />
                                                    <div>
                                                        <h3>John Larson</h3>
                                                        <h4>Entrepreneur</h4>
                                                        <div className="stars">
                                                            <i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" /><i className="bi bi-star-fill" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left" />
                                                    Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore.
                                                    <i className="bi bi-quote quote-icon-right" />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-pagination" />
                            </div>
                        </div>
                    </section> */}
                    {/* End Testimonials Section */}
                    {/* ======= Portfolio Section ======= */}
                    <section id="portfolio" className="portfolio sections-bg">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>Template</h2>
                                <p>Themes สามารถเลือกได้ มีทั้งฟรี และ เสียเงินเพิ่ม หรือทำออกแบบ ลอกเว็บไซต์อื่นๆ  Themes ด้านล่าง แค่ส่วนนึงขอเพิ่มเติมที่แอดมินได้เลย !!</p>
                            </div>
                            <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order" data-aos="fade-up" data-aos-delay={100}>
                                <div>
                                    <ul className="portfolio-flters">
                                        <li data-filter="*" className="filter-active">All</li>
                                    </ul>{/* End Portfolio Filters */}
                                </div>
                                <div className="row gy-4 portfolio-container">
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2023/11/Screenshot-2566-11-18-at-11.25.35.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2023/11/Screenshot-2566-11-18-at-11.25.35.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/whanmhoo569/" title="More Details">Demo</a></h4>
                                                <p>Themes 1</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2023/01/Screenshot-2566-01-09-at-16.57.10.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2023/01/Screenshot-2566-01-09-at-16.57.10.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/918kissauto/" title="More Details">Demo</a></h4>
                                                <p>Themes 2</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2022/10/Screenshot-2565-10-20-at-20.52.50.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2022/10/Screenshot-2565-10-20-at-20.52.50.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/csongreen/" title="More Details">Demo</a></h4>
                                                <p>Themes 3</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-books">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2022/12/Screenshot-2565-12-11-at-15.03.34.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2022/12/Screenshot-2565-12-11-at-15.03.34.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/vipslot68/" title="More Details">Demo</a></h4>
                                                <p>Themes 4</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2022/09/screenshot01.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2022/09/screenshot01.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/bfth/" title="More Details">Demo</a></h4>
                                                <p>Themes 5</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2022/06/Screen-Shot-2565-06-12-at-13.11.19.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2022/06/Screen-Shot-2565-06-12-at-13.11.19.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/ufajoker356/" title="More Details">Demo</a></h4>
                                                <p>Themes 6</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2022/08/Screenshot-2565-08-27-at-20.48.13.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2022/08/Screenshot-2565-08-27-at-20.48.13.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/sr168/" title="More Details">Demo</a></h4>
                                                <p>Themes 7</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-books">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2022/02/download-1.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2022/02/download-1.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/ufaapollo/" title="More Details">Demo</a></h4>
                                                <p>Themes 8</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2021/12/screenshot-1.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2021/12/screenshot-1.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/bestpurplee/" title="More Details">Demo</a></h4>
                                                <p>Themes 9</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}

                                    <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2023/07/Screenshot-2566-07-09-at-13.15.25.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2023/07/Screenshot-2566-07-09-at-13.15.25.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/ezslotnew/" title="More Details">Demo</a></h4>
                                                <p>Themes 10</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2021/11/02.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2021/11/02.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/allbet/" title="More Details">Demo</a></h4>
                                                <p>Themes 11</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-app">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2022/09/Screenshot-2565-09-11-at-13.55.04.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2022/09/Screenshot-2565-09-11-at-13.55.04.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/pgslot88asia/" title="More Details">Demo</a></h4>
                                                <p>Themes 12</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}







                                    <div className="col-xl-4 col-md-6 portfolio-item filter-product">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2021/11/05.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2021/11/05.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/aenew/" title="More Details">Demo</a></h4>
                                                <p>Free Themes 1</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-branding">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2021/11/04.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2021/11/04.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/wmnew/" title="More Details">Demo</a></h4>
                                                <p>Free Themes 2 </p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                    <div className="col-xl-4 col-md-6 portfolio-item filter-books">
                                        <div className="portfolio-wrap">
                                            <a href="https://ideabet.net/wp-content/uploads/2021/11/Screen-Shot-2564-11-30-at-02.25.03.jpg" data-gallery="portfolio-gallery-app" className="glightbox"><img src="https://ideabet.net/wp-content/uploads/2021/11/Screen-Shot-2564-11-30-at-02.25.03.jpg" className="img-fluid" alt="" /></a>
                                            <div className="portfolio-info">
                                                <h4><a href="https://ideabet.live/theme/wmbk/" title="More Details">Demo</a></h4>
                                                <p>Free Themes 3</p>
                                            </div>
                                        </div>
                                    </div>{/* End Portfolio Item */}
                                </div>{/* End Portfolio Container */}
                            </div>
                        </div>
                    </section>{/* End Portfolio Section */}
                    {/* ======= Our Team Section ======= */}
                    {/* ======= Pricing Section ======= */}
                    <section id="pricing" className="pricing sections-bg">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>Pricing</h2>
                                <p>สามารถเลือกใช้เฉพาะที่ต้องการเพื่อปรับราคารายเดือนได้เลย</p>
                            </div>
                            <div className="row g-4 py-lg-5" style={{ marginTop: "-100px" }} data-aos="zoom-out" data-aos-delay={100}>
                                <div className="col-lg-12">
                                    <div className="pricing-item">
                                        <h3>เลือกฟังก์ชั่นเฉพาะที่ใช้งาน คำนวณราคาไห้ได้เลย</h3>
                                        <div className="icon">
                                            <i className="bi bi-send" />
                                        </div>
                                        <div className="panel panel-default">
                                            <table className="table table-bordered table-condensed step">
                                                <thead>
                                                    <tr>
                                                        <td className="text-center col-xs-1">ส่วนลด</td>
                                                        <td className="text-center col-xs-1">แรกเข้า</td>
                                                        <td className="text-center col-xs-1">เดือนต่อไป</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th className="text-center rowtotal mono xtes" >{Number.parseFloat(0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                                        <th className="text-center rowtotal mono xtes" > {Number.parseFloat(this.state.pricelist).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                                        <th className="text-center rowtotal mono xtes"> {Number.parseFloat(this.state.pricemonth).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <br></br>
                                        <div className="row" style={{ textAlign: "left" }}>
                                            <div class="col-lg-4">
                                                <table className="table table-alternate-spaced mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                no
                                                            </th>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                ฟังกชั่น
                                                            </th>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                check
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(this.state.cuslist1 === null) ?
                                                            <tr key={1}>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr> : (this.state.cuslist1.length === null) ? <tr key={1}><td><span>ไม่มีข้อมูล </span></td></tr> : this.state.cuslist1.map((listitem, i) => (
                                                                <React.Fragment key={i}>
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <span>{i + 1} </span>
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <span> {listitem.systemname}</span>
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <Switch
                                                                                onClick={() => this.toggleCheck2(listitem, 2)}
                                                                                checked={(listitem.statusx === 0) ? false : true}
                                                                                className="switch-medium"
                                                                            />
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <a onClick={() => this.toggleModal(listitem, 2)} style={{ cursor: "pointer" }} >
                                                                                <img src="https://wiki.octave.org/wiki/images/a/ae/Info_icon.svg" width={18} height={18} data-aos="zoom-out" />
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={999} className="divider"></tr>
                                                                </React.Fragment>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                <br></br>
                                                <br></br>

                                                <table className="table table-alternate-spaced mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                no
                                                            </th>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                ฟังกชั่น
                                                            </th>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                check
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(this.state.cuslist2 === null) ?
                                                            <tr key={1}>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr> : (this.state.cuslist2.length === null) ? <tr key={1}><td><span>ไม่มีข้อมูล </span></td></tr> : this.state.cuslist2.map((listitem, i) => (
                                                                <React.Fragment key={i}>
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <span>{i + 1} </span>
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <span> {listitem.systemname}</span>
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <Switch
                                                                                onClick={() => this.toggleCheck2(listitem, 3)}
                                                                                checked={(listitem.statusx === 0) ? false : true}
                                                                                className="switch-medium"
                                                                            />
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <a onClick={() => this.toggleModal(listitem, 3)} style={{ cursor: "pointer" }} >
                                                                                <img src="https://wiki.octave.org/wiki/images/a/ae/Info_icon.svg" width={18} height={18} data-aos="zoom-out" />
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={999} className="divider"></tr>
                                                                </React.Fragment>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-lg-4">
                                                <table className="table table-alternate-spaced mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                no
                                                            </th>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                ฟังกชั่น
                                                            </th>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                check
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(this.state.cuslist === null) ?
                                                            <tr key={1}>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr> : (this.state.cuslist.length === null) ? <tr key={1}><td><span>ไม่มีข้อมูล </span></td></tr> : this.state.cuslist.map((listitem, i) => (
                                                                <React.Fragment key={i}>
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <span>{i + 1} </span>
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <img
                                                                                src={listitem.image}
                                                                                className="-img rounded-circle"
                                                                                width={25}
                                                                                height={25}
                                                                                alt=""
                                                                            />
                                                                            <span> {listitem.systemname}</span>
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <Switch
                                                                                onClick={() => this.toggleCheck2(listitem, 1)}
                                                                                checked={(listitem.statusx === 0) ? false : true}
                                                                                className="switch-medium"
                                                                            />
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <a onClick={() => this.toggleModal(listitem, 1)} style={{ cursor: "pointer" }}>
                                                                                <img src="https://wiki.octave.org/wiki/images/a/ae/Info_icon.svg" width={18} height={18} data-aos="zoom-out" />
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={999} className="divider"></tr>
                                                                </React.Fragment>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-lg-4">
                                                <table className="table table-alternate-spaced mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                no
                                                            </th>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                ฟังกชั่น
                                                            </th>
                                                            <th
                                                                className="font-size-lg font-weight-normal pb-4 text-capitalize text-dark"
                                                                scope="col">
                                                                check
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(this.state.cuslist3 === null) ?
                                                            <tr key={1}>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr> : (this.state.cuslist3.length === null) ? <tr key={1}><td><span>ไม่มีข้อมูล </span></td></tr> : this.state.cuslist3.map((listitem, i) => (
                                                                <React.Fragment key={i}>
                                                                    <tr key={i}>
                                                                        <td>

                                                                            <span>{i + 1} </span>
                                                                        </td>
                                                                        <td className="font-size-lg ">

                                                                            <span> {listitem.systemname}</span>
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <Switch
                                                                                onClick={() => this.toggleCheck2(listitem, 4)}
                                                                                checked={(listitem.statusx === 0) ? false : true}
                                                                                className="switch-medium"
                                                                            />
                                                                        </td>
                                                                        <td className="font-size-lg ">
                                                                            <a onClick={() => this.toggleModal(listitem, 4)} style={{ cursor: "pointer" }} >
                                                                                <img src="https://wiki.octave.org/wiki/images/a/ae/Info_icon.svg" width={18} height={18} data-aos="zoom-out" />
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr key={999} className="divider"></tr>
                                                                </React.Fragment>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>{/* End Pricing Section */}
                    {/* ======= Frequently Asked Questions Section ======= */}
                    <section id="faq" className="faq">
                        <div className="container" data-aos="fade-up">
                            <div className="row gy-4">
                                <div className="col-lg-4">
                                    <div className="content px-xl-5">
                                        <h3>Frequently Asked <strong>Questions</strong></h3>
                                        <p>
                                            คำถามที่พบบ่อย
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="accordion accordion-flush" id="faqlist" data-aos="fade-up" data-aos-delay={100}>
                                        <div className="accordion-item">
                                            <h3 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                                    <span className="num">1.</span>
                                                    Javis ทำอะไร
                                                </button>
                                            </h3>
                                            <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                                <div className="accordion-body">
                                                    Javis ทำหลายส่วน เป็นผู้ให้บริการ ไม่ว่าจะเป็นออกแบบหน้าเว็บพนัน มีระบบ AUto เติมเงิน ฝากเงิน ถอนเงิน แบบอัติโนมัติ มีหลายกระดานให้เลือกใช้งาน รับดูแลการตลาด ดูแล SEO ไห้แก่ลูกค้า มีซัพพอท 24 ชม.
                                                </div>
                                            </div>
                                        </div>{/* # Faq item*/}
                                        <div className="accordion-item">
                                            <h3 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                                    <span className="num">2.</span>
                                                    ต้องใช้เงินทุนเริ่มต้นเท่าไรในการเปิดเว็บไซต์ ?
                                                </button>
                                            </h3>
                                            <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                                <div className="accordion-body">
                                                    หากร่วมงานกับเราสามารถกำหนด และมองเห็นรายเดือนได้ว่าประมาณเท่าไหร่ คาดการณ์ได้ล่วงหน้า เงินทุนและเงินสำรองในการเปิดเว็บไซต์ประมาณ 50,000-100,000 บาท.
                                                </div>
                                            </div>
                                        </div>{/* # Faq item*/}
                                        <div className="accordion-item">
                                            <h3 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                                    <span className="num">3.</span>
                                                    ใช้เวลาทำเว็บไซต์?
                                                </button>
                                            </h3>
                                            <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                                <div className="accordion-body" style={{ textAlign: "left" }}>
                                                    - ระยะเวลาการดำเนินงานไม่เกิน 7 วัน ไม่รวมเสาร์-อาทิตย์ และวันนักขัตฤกษ์(กรณีเลือกแค่ระบบหลังบ้าน Auto / Agent Game)<br></br>
                                                    - ระยะเวลาการดำเนินงาน 14 วัน ไม่รวม เสาร์-อาทิตย์ และวันนักขัตฤกษ์(กรณีให้ทางบริษัทออกแบบหน้าเว็บใหม่)<br></br>
                                                    - ระยะเวลาการดำเนินงาน 7 วัน ไม่รวม เสาร์-อาทิตย์ และวันนักขัตฤกษ์(Themes เสียเงิน)<br></br>
                                                    - ระยะเวลาการดำเนินงาน 2 วัน ไม่รวม เสาร์-อาทิตย์ และวันนักขัตฤกษ์(Themes ฟรี)
                                                </div>
                                            </div>
                                        </div>{/* # Faq item*/}
                                        <div className="accordion-item">
                                            <h3 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                                    <span className="num">4.</span>
                                                    ทีมงานดูแลหลังเปิดเว็บหรือไม่ ?
                                                </button>
                                            </h3>
                                            <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                                <div className="accordion-body">
                                                    ทางบริษัทของเราจะมีทีมงานซัพพอท คอยซัพพอร์ตช่วยเหลือลูกค้าด้านต่างๆ
                                                    ตลอด 24 ชั่วโมง.
                                                </div>
                                            </div>
                                        </div>{/* # Faq item*/}
                                        <div className="accordion-item">
                                            <h3 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                                                    <span className="num">5.</span>
                                                    สิ่งที่ต้องเตรียมตอนเปิดเว็บมีอะไรบ้าง ?
                                                </button>
                                            </h3>
                                            <div id="faq-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                                <div className="accordion-body" style={{ textAlign: "left" }}>
                                                    1. ชื่อเว็บหรือชื่อโดเมนที่ต้องการใช้ (ตามความต้องการของลูกค้า)<br></br>
                                                    2. เตรียมบัญชีธนาคาร แอดมิน สถานที่ โทรศัพท์ และคอมพิวเตอร์​สำหรับใช้
                                                    ดำเนินการ<br></br>
                                                    3. เตรียมรูปแบบเว็บ โลโก้ สีหน้าเว็บ หรือตัวอย่างและเงื่อนไขโปรโมชันที่
                                                    ต้องการให้ทางบริษัทออกแบบ<br></br>
                                                    4. ข้อมูลที่เตรียมเพื่อเชื่อมระบบหลังบ้าน<br></br>
                                                    - Line : Official Account <br></br>
                                                    - Facebook Page<br></br>
                                                    - Line : Token        <br></br>
                                                    - Sms2pr<br></br>
                                                </div>
                                            </div>
                                        </div>{/* # Faq item*/}
                                        <div className="accordion-item">
                                            <h3 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-6">
                                                    <span className="num">6.</span>
                                                    ชำระค่าเครดิตผ่านช่องทางไหน ?
                                                </button>
                                            </h3>
                                            <div id="faq-content-6" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                                <div className="accordion-body" style={{ textAlign: "left" }}>
                                                    1. ค่าเครดิตเป็นแบบซื้อขาด ชำระผ่านเป็นสกุลเงินดิจิทัล USDT<br></br>
                                                    2. โอนจ่ายผ่านบัญชีที่เราส่งให้<br></br>
                                                </div>
                                            </div>
                                        </div>{/* # Faq item*/}



                                        <div className="accordion-item">
                                            <h3 className="accordion-header">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-7">
                                                    <span className="num">7.</span>
                                                    จะเชื่อมั่นได้ยังไงว่าบริษัทจะไม่โกง ?
                                                </button>
                                            </h3>
                                            <div id="faq-content-7" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                                <div className="accordion-body" style={{ textAlign: "left" }}>
                                                    1. เราเปิดเว็บมาเกิน 3,000 แบรนด์ ซึ่งเชื่อถือได้อย่างแน่นอน<br></br>
                                                    2. เราทำระบบเติมเงินให้เว็บใหญ่ ต่างประเทศ ที่น่าเชื่อถือ<br></br>
                                                    ติดต่อสอบถามเราได้ที่ Telegram @salejarvis
                                                </div>
                                            </div>
                                        </div>{/* # Faq item*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>{/* End Frequently Asked Questions Section */}

                    {/* ======= Recent Blog Posts Section ======= */}
                    {/* <section id="recent-posts" className="recent-posts sections-bg">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>Recent Blog Posts</h2>
                                <p>Consequatur libero assumenda est voluptatem est quidem illum et officia imilique qui vel architecto accusamus fugit aut qui distinctio</p>
                            </div>
                            <div className="row gy-4">
                                <div className="col-xl-4 col-md-6">
                                    <article>
                                        <div className="post-img">
                                            <img src="assets/img/blog/blog-1.jpg" alt="" className="img-fluid" />
                                        </div>
                                        <p className="post-category">Politics</p>
                                        <h2 className="title">
                                            <a href="blog-details.html">Dolorum optio tempore voluptas dignissimos</a>
                                        </h2>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/img/blog/blog-author.jpg" alt="" className="img-fluid post-author-img flex-shrink-0" />
                                            <div className="post-meta">
                                                <p className="post-author">Maria Doe</p>
                                                <p className="post-date">
                                                    <time dateTime="2022-01-01">Jan 1, 2022</time>
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <article>
                                        <div className="post-img">
                                            <img src="assets/img/blog/blog-2.jpg" alt="" className="img-fluid" />
                                        </div>
                                        <p className="post-category">Sports</p>
                                        <h2 className="title">
                                            <a href="blog-details.html">Nisi magni odit consequatur autem nulla dolorem</a>
                                        </h2>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/img/blog/blog-author-2.jpg" alt="" className="img-fluid post-author-img flex-shrink-0" />
                                            <div className="post-meta">
                                                <p className="post-author">Allisa Mayer</p>
                                                <p className="post-date">
                                                    <time dateTime="2022-01-01">Jun 5, 2022</time>
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <article>
                                        <div className="post-img">
                                            <img src="assets/img/blog/blog-3.jpg" alt="" className="img-fluid" />
                                        </div>
                                        <p className="post-category">Entertainment</p>
                                        <h2 className="title">
                                            <a href="blog-details.html">Possimus soluta ut id suscipit ea ut in quo quia et soluta</a>
                                        </h2>
                                        <div className="d-flex align-items-center">
                                            <img src="assets/img/blog/blog-author-3.jpg" alt="" className="img-fluid post-author-img flex-shrink-0" />
                                            <div className="post-meta">
                                                <p className="post-author">Mark Dower</p>
                                                <p className="post-date">
                                                    <time dateTime="2022-01-01">Jun 22, 2022</time>
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {/* End Recent Blog Posts Section */}
                    {/* ======= Contact Section ======= */}
                    <section id="contact" className="contact">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>Contact</h2>
                                <p>ติดต่อเราได้ที่นี่ หรือ เทเลแกรม @salejarvis</p>
                            </div>
                            <div className="row gx-lg-0 gy-4">
                                <div className="col-lg-4">
                                    <div className="info-container d-flex flex-column align-items-center justify-content-center">
                                        <div className="info-item d-flex">
                                            <i className="bi bi-geo-alt flex-shrink-0" />
                                            <div>
                                                <h4>Location:</h4>
                                                <p>A108 Adam Street, New York, NY 535022</p>
                                            </div>
                                        </div>{/* End Info Item */}
                                        <div className="info-item d-flex">
                                            <i className="bi bi-envelope flex-shrink-0" />
                                            <div>
                                                <h4>Email:</h4>
                                                <p>info@salejarvis.live</p>
                                            </div>
                                        </div>{/* End Info Item */}
                                        <div className="info-item d-flex">
                                            <i className="bi bi-phone flex-shrink-0" />
                                            <div>
                                                <h4>Call:</h4>
                                                <p>+67 556 5588</p>
                                            </div>
                                        </div>{/* End Info Item */}
                                        <div className="info-item d-flex">
                                            <i className="bi bi-clock flex-shrink-0" />
                                            <div>
                                                <h4>Open Hours:</h4>
                                                <p>Mon-Sat: 24H</p>
                                            </div>
                                        </div>{/* End Info Item */}
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                            </div>
                                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                                <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                                        </div>
                                        <div className="form-group mt-3">
                                            <textarea className="form-control" name="message" rows={7} placeholder="Message" required defaultValue={""} />
                                        </div>
                                        <div className="my-3">
                                            <div className="loading">Loading</div>
                                            <div className="error-message" />
                                            <div className="sent-message">Your message has been sent. Thank you!</div>
                                        </div>
                                        <div className="text-center"><button type="submit">Send Message</button></div>
                                    </form>
                                </div>{/* End Contact Form */}
                            </div>
                        </div>
                    </section>{/* End Contact Section */}
                </main>
            </div>

        );
    }
}

export default App;
