
import './App.css';
import React, { Component } from 'react'; //different
import "@fontsource/kanit"; // Defaults to weight 400
import "@fontsource/kanit/400.css"; // Specify weight
import "@fontsource/kanit/400-italic.css"; // Specify weight and style
class App extends Component {
    constructor(props) {
        super(props)
    }
    async componentDidMount() {
    }
    render() {
        return (
            <div>
                <section id="hero" className="hero">
                    <div className="container position-relative">
                        <div className="row gy-5" data-aos="fade-in">
                            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
                                <h2>เปิดเว็บพนัน <span>ระบบที่จัดการราคาได้</span></h2>
                                <p>เปิดเว็บพนันมี Auto มีหลายกระดานให้เลือก ทีมงาน Support 24ชม. รองรับหลายภาษา.</p>
                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <a href="#about" className="btn-get-started">ร่วมงานกับเรา</a>
                                    <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle" /><span>ดูวิดีโอตัวอย่าง</span></a>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <img src="https://miro.medium.com/v2/resize:fit:1400/1*cezMLgS1Zgpc2d4H-71Kqw.png" className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay={100} />
                            </div>
                        </div>
                    </div>
                    <div className="icon-boxes position-relative">
                        <div className="container position-relative">
                            <div className="row gy-4 mt-5">
                                <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay={100}>
                                    <div className="icon-box">
                                        <div className="icon"><i className="bi bi-easel" /></div>
                                        <h4 className="title"><a href className="stretched-link">จ่ายเฉพาะที่ใช้งาน</a></h4>
                                    </div>
                                </div>{/*End Icon Box */}
                                <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay={200}>
                                    <div className="icon-box">
                                        <div className="icon"><i className="bi bi-gem" /></div>
                                        <h4 className="title"><a href className="stretched-link">ถือสู้สูงสุด 93%</a></h4>
                                    </div>
                                </div>{/*End Icon Box */}
                                <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay={300}>
                                    <div className="icon-box">
                                        <div className="icon"><i className="bi bi-geo-alt" /></div>
                                        <h4 className="title"><a href className="stretched-link">ซัพพอต การตลาด</a></h4>
                                    </div>
                                </div>{/*End Icon Box */}
                                <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay={500}>
                                    <div className="icon-box">
                                        <div className="icon"><i className="bi bi-command" /></div>
                                        <h4 className="title"><a href className="stretched-link">ซัพพอต 24ชม.</a></h4>
                                    </div>
                                </div>{/*End Icon Box */}
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        );
    }
}

export default App;
