import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Main from './main';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
           <Route path="/" element={<Main />} /> 
          {/* <Route path="/casino" element={<Casino />} />
          <Route path="/slot" element={<Slot />} />
          <Route path="/sport" element={<Sport />} />
          <Route path="/arcade" element={<Arcade />} />
          <Route path="/fising" element={<Fising />} />
          <Route path="/promotions" element={<Promotion />} />
          <Route path="/freegame" element={<Freegame />} />
          <Route path="/listgameall/*" element={<Linklistall />} />
          <Route path="/listgamealldemo/*" element={<Linklistalldemo />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register/*" element={<Register />} />
          <Route path='/Paoyingchub' element={<Promotion />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
